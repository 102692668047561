<template>
    <div id="page-lap-penyakit">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_mulai"
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_selesai"
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tipe Obat
                    </template>
                    <b-form-select
                      :options="option_obat"
                      size="sm"
                      v-model="data_search.tipe_obat"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="4" xl="4">

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="options_diagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_diagnosa"
                      size="sm"
                      v-model="data_search.diagnosa_id"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kecamatan
                    </template>
                    <multiselect
                      :options="options_kecamatan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_kecamatan"
                      size="sm"
                      v-model="data_search.kecamatan_id"
                      @input="getKelurahan(data_search.kecamatan_id)"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kelurahan/Desa
                    </template>
                    <multiselect
                      id=""
                      :options="options_kelurahan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      size="sm"
                      label="nama_kelurahan"
                      v-model="data_search.kelurahan_id"
                    ></multiselect>
                  </b-form-group>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_puskesmas"
                            name="check_puskesmas"
                            v-model="checked"
                            @change="toggleSelect()"
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_puskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelect()"
                          >
                            {{ item.nama_puskesmas }}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Gudang</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_poli"
                            name="check_poli"
                            v-model="checked_poli"
                            @change="toggleSelectPoli()"
                            
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_poli" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelectPoli()"
                          >
                            {{ item.nama_gudang}}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN HARIAN - PENGELUARAN OBAT</strong></h5>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-table-simple striped>
                    <b-thead>
                      <b-tr>
                        <b-th>No.</b-th>
                        <b-th>Kode Produk</b-th>
                        <b-th>Nama Obat</b-th>
                        <b-th>Sumber Anggaran</b-th>
                        <b-th>Tahun Anggaran</b-th>
                        <b-th>Jumlah Obat</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td>{{ idx+1 }}</b-td>
                        <b-td>{{ item.kode_produk }}</b-td>
                        <b-td>{{ item.nama_barang }}</b-td>
                        <b-td>{{ item.nama_sumber_dana }}</b-td>
                        <b-td>{{ item.tahun_anggaran }}</b-td>
                        <b-td>{{ item.total_qty }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>
  
<script>
  export default {
    name: "kunjungan_pasien",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        data_search: {
          tanggal_mulai: null,
          tanggal_selesai: null,
          bulk_gudang: [],
          bulk_puskesmas: [],
          tipe_obat: null,
          // halaman: 0,
          // jumlah: 1000,
        },
        option_obat: [
          {value: null, text: "Semua"},
          {value: "resep", text: "Resep"},
          {value: "non resep", text: "Non Resep"},
        ],
        items: [],
        checked: false,
        checked_poli: "",
        select_puskesmas: [],
        select_poli: [],
        options_puskesmas: [],
        options_poli: [],
      };
    },
    computed: {
      sortOptions() {
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
    //   this.getKecamatan()
      this.getData();
      this.getDataKunjungan()
    },
    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let puskesmas = await vm.$axios.post("/puskesmas/list")
        if(puskesmas.data.data.length){
            vm.options_puskesmas = puskesmas.data.data
            for (let i = 0; i < vm.options_puskesmas.length; i++) {
                let x = vm.options_puskesmas[i];
                x.checked = false
            }
        }
        let poli = await vm.$axios.post("/ms_gudang/list")
        // console.log(poli, 'ini gudang');
        if(poli.data.data.length){
            vm.options_poli = poli.data.data
            for (let i = 0; i < vm.options_poli.length; i++) {
                let x = vm.options_poli[i];
                x.checked = false
            }
        }
      },
      getDataKunjungan(){
        let vm = this
        if(vm.select_puskesmas.length){
          vm.data_search.bulk_puskesmas = vm.select_puskesmas
        }
        if(vm.select_poli.length){
          vm.data_search.bulk_gudang = vm.select_poli
        }
        vm.$axios.post("/ms_barang/laporan_pengeluaran_obat", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.data.length){
            vm.items = res.data.data
          }else {
            vm.items = []
            // vm.$store.commit('set_alert', {msg: "TIDAK ADA DATA", variant: "danger", showing: true})
          }
        })
        .catch((err) => {
          vm.$store.commit('set_alert', {msg: err.message, variant: "danger", showing: true})
        })
      },
      toggleSelect: function () {
        let select = this.checked;
        console.log(this.checked);
        this.options_puskesmas.forEach(function (data) {
            data.checked = select;
        });
        this.select_puskesmas = []
        for (let i = 0; i < this.options_puskesmas.length; i++) {
          let x = this.options_puskesmas[i];
          if(x.checked == true){
            this.select_puskesmas.push(x.id)
          }
        }
        this.$forceUpdate()
      },
      toggleSelectPoli: function () {
        let select = this.checked_poli;
        // console.log(this.checked_poli);
        this.options_poli.forEach(function (data) {
            data.checked = select;
        });
        this.select_poli = []
        for (let i = 0; i < this.options_poli.length; i++) {
          let x = this.options_poli[i];
          if(x.checked == true){
            this.select_poli.push(x.ms_gudang_id)
          }
        }
        this.$forceUpdate()
      },
      addSelect(){  
        let vm = this
        vm.select_puskesmas = []
        for (let i = 0; i < vm.options_puskesmas.length; i++) {
            let x = vm.options_puskesmas[i];
            if(x.checked == true){
              vm.select_puskesmas.push(x.id)
            }
          }
      },
      addSelectPoli(){  
        let vm = this
        vm.select_poli = []
        for (let i = 0; i < vm.options_poli.length; i++) {
            let x = vm.options_poli[i];
            if(x.checked == true){
              vm.select_poli.push(x.ms_gudang_id)
            }
          }
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>
  <style>
  #page-lap-penyakit .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }

  #page-lap-penyakit .custom-control-label{
    padding-top:2.5px
  }
  </style>
  